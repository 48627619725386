import * as React from 'react';
import { NavLink } from "react-router-dom";

export function AppNav(): JSX.Element {
    return (
        <div className="flexBox justifyBetween gap-1">
            <NavLink to="/" className="primary-color linkText pa-2">Home</NavLink>
            <NavLink to="/page1" className="primary-color linkText pa-2">Page 1</NavLink>
        </div>
    )
}
