import * as React from "react";

export function NotFound(): JSX.Element {
    return (
        <div className="basicBox ma-2 flexBox justifyAround textAlignCenter">
            <div className="flexBoxVertical gap-2">
                <h3>Page Not Found</h3>
                <p className="mb-0">
                    If this error persists, please contact the web admin.
                </p>
                <a href="mailto:tsundraki@gmail.com">tsundraki@gmail.com</a>
            </div>
        </div>
    );
}