import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Page from './sections';

export type AppRoute = {
    id: string,
    path: string,
    area: string,
    element: JSX.Element
}

export const AppRoutes: AppRoute[] = [
    { id: "Home", path: '/', area: "", element: <Page.HomePage /> },
];

export function AppRouter(): JSX.Element {
    return (
        <Routes>
            {AppRoutes.map((route: AppRoute, index: number) => (
                <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<Page.NotFound/>} />
        </Routes>
    );
}
