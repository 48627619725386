import * as React from 'react';
import './style';
import { AppNav } from './AppNav'
import { AppRouter } from './AppRouter';

export default function App(): JSX.Element {
  return (
    <div className="flexBoxVertical flex-100 dark-grey-color">
        <Header />
        <AppRouter />
    </div>
  );
}

function Header(): JSX.Element {
  return (
    <header className="flexBox justifyAround primary-bottom-border-strong dark-grey-color">
        <h4 className="white-text pt-1 pb-1">Tsundraki Studio</h4>
        <AppNav />
    </header>
  );
}
